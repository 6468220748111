@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

body {
  /* height: 100dvh; */
  /* turning it off for the final page */
  overflow: visible;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, p {
  margin: 0;
}

h1 {
  font-size: calc(1.75 * var(--font-size-1));
  font-weight: var(--font-weight-bold);
}

h2, h3 {
  font-size: calc(1.25 * var(--font-size-1));
  font-weight: var(--font-weight-bold);
}

p {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1-5);
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: "Roboto" sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --theme-100: var(--rpf-teal-100);
  --theme-200: var(--rpf-teal-200);
  --theme-300: var(--rpf-teal-800);
  --theme-400: var(--rpf-teal-900);
  --rpf-squiggle-color: var(--theme-300);
}
