.c-final-page {
  align-items: center;
  background: var(--theme-100);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--space-2);
  gap: var(--space-3);

  @media (min-width: 400px) {
    padding: var(--space-3);
  }
}

.c-final-page-section {
  background-color: var(--rpf-white);
  border-radius: var(--space-1);
  padding: var(--space-4);
}

.c-final-page-section--completed {
  position: relative;
  background-image: url("../assets/final/line.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
  box-sizing: border-box;
  padding-block-start: 3.4vw;
  width: 100%;
  min-height: 400px;

  // beyond the width of the bg it should be 100% of the width of the container
  @media (min-width: 1472px) {
    background-size: 100% auto;
  }
}

.c-final-page-section--completed__header {
  text-align: center;
}

.c-final-page-section--completed__image {
  max-width: 100%;
}

.c-final-page-section--completed__text {
  margin-block: var(--space-2);
}

.c-final-page-section--completed__buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--space-2);
  align-items: center;

  @media (min-width: 400px) {
    flex-direction: row;
    justify-content: center;
  }
}

.c-final-page-section--completed__code {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  width: 100%;
  box-sizing: border-box;
  padding-block-start: var(--space-4);

  @media (min-width: 800px) {
    flex-direction: row;
  }
}

.c-final-page-section--completed__code-input,
.c-final-page-section--completed__code-output {
  flex: 1;
}

.c-final-page-section--completed__code-input {
  overflow: hidden;
  box-sizing: border-box;
}

.c-final-page-section--completed__code-output {
  @media (min-width: 800px) {
    max-width: 500px;
  }
}

.c-final-page-section--completed__code-heading {
  margin-block-end: var(--space-2);
}

.c-final-page-section--python, .c-final-page-section--html {
  flex-direction: column;
  display: flex;
  max-width: 1200px;
  box-sizing: border-box;
}

.c-final-page-section--python {
  align-items: center;
  padding: 0;

  @media (min-width: 600px) {
    flex-direction: row;
  }
}

.c-final-page-section--python__text {
  margin: var(--space-1) 0 var(--space-3) 0;
}

.c-final-page-section--python__content {
  padding: var(--space-4);
}

.c-final-page-section--python__image {
  border-radius: var(--space-1);
  width: 100%;

  @media (min-width: 600px) {
    width: 50%;
  }
}

.c-final-page-section--html {
  width: 100%;
  @media (min-width: 600px) {
    flex-direction: column;
  }
}

.c-final-page-section--html__text {
  margin: var(--space-1) 0 var(--space-4) 0;
}

.c-final-page-section--html__cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--space-3);
  justify-items: space-between;
  margin-block-end: var(--space-4);
}

.c-final-page-section--html__button {
  margin-inline: auto;
}
