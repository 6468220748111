.c-error-message {

  .rpf-alert {
    align-items: flex-start;
    border: none;
    border-radius: var(--space-2);
    flex-direction: column;
    margin: 0;

    &__title {
      font-size: var(--font-size-1-5);
      font-weight: var(--font-weight-bold);
      align-items: center;
      color: var(--rpf-alert-error);
      display: flex;
      gap: var(--space-1);
    }

    &__body {
      display: initial;
    }
  }
}
