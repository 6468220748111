.c-editor {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: fill-available;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  width: 100%;
}

.c-editor__wc {
  bottom: 0;
  container-name: c-editor;
  container-type: normal;
  display: flex;
  height: 100%;
  left: 0;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: fill-available;
  -ms-overflow-style: none; /* Remove scrollbar for IE and Edge */
  overflow: auto;
  position: absolute;
  right: 0;
  scrollbar-width: none; /* Remove scroll bar for Firefox */
  top: 0;
}

::part(editor-root) {
  --editor-color-layer-1: var(--rpf-navy-100);
  --editor-color-layer-2: var(--rpf-white);
  --editor-color-layer-3: var(--rpf-white);
  --editor-color-outline: var(--rpf-grey-150);
  --editor-color-theme: var(--rpf-navy-800);
  --editor-color-theme-secondary: var(--rpf-navy-800);
  --editor-color-theme-tertiary: var(--rpf-navy-100);
  --editor-color-text: var(--rpf-text-primary);
  --editor-color-text-secondary: var(--rpf-text-secondary);

  --rpf-button-primary-background-color: var(--editor-color-theme);
  --rpf-button-primary-background-color-focus: var(--editor-color-theme);
  --rpf-button-primary-background-color-hover: var(--editor-color-theme);
  --rpf-button-primary-background-color-active: var(--rpf-navy-600);
  --rpf-button-primary-background-color-disabled: var(--rpf-navy-200);
  --rpf-button-primary-text-color: var(--rpf-white);

  --rpf-button-secondary-background-color: var(--editor-color-theme);
  --rpf-button-secondary-background-color-focus: var(--editor-color-theme);
  --rpf-button-secondary-background-color-hover: var(--editor-color-theme);
  --rpf-button-secondary-background-color-active: var(--rpf-navy-600);
  --rpf-button-secondary-background-color-disabled: var(--rpf-navy-200);
  --rpf-button-secondary-text-color: var(--rpf-black);

  --sidebar-option-selected-border: var(--editor-color-theme-secondary);
  --sidebar-option-selected-background: var(--editor-color-theme-tertiary);
  --sidebar-option-selected-background-hover: var(--rpf-navy-200);

  --rpf-tab-button-background: var(--rpf-white);
  --rpf-tab-button-hover: var(--rpf-grey-100);
  --rpf-tab-border-bottom-selected: var(--rpf-navy-900);

  --rpf-select-buttons-tick-color: var(--rpf-white);

  --rpf-files-list-item-active: var(--rpf-navy-200);
  --rpf-files-list-item-hover: var(--rpf-navy-100);

  display: block;
  flex: 1 1 auto;
  width: 100%;
}
