.c-exercise {
  align-items: center;
  background: var(--theme-100);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--space-2);
  gap: var(--space-3);

  @media (min-width: 400px) {
    padding: var(--space-3);
  }
}
