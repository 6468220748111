@use '@raspberrypifoundation/design-system-core/scss/mixins/_typography.scss';

.title-bar {
  align-content: center;
  align-items: center;
  align-self: stretch;
  background-color: var(--rpf-white, white);
  border-radius: var(--space-1);
  display: flex;
  flex-wrap: wrap;

  &__name {
    @include typography.regular-2;

    align-items: center;
    border-bottom: 2px solid var(--rpf-grey-150);
    color: var(--rpf-text, #212121);
    display: flex;
    flex: 1 0 0;
    font-weight: var(--font-weight-bold);
    gap: var(--space-1, 8px);
    justify-content: center;
    min-width: 200px;
    padding: var(--space-2);
    text-align: center;
    width: 100%;

    @media (min-width: 600px){
      border-bottom: none;
      justify-content: flex-start;
      text-align: left;
    }
  }

  &__step-navigation {
    @include typography.regular-1-5;

    align-items: center;
    display: flex;
    font-weight: var(--font-weight-bold);
    gap: var(--space-1, 8px);
    justify-content: space-between;
    padding: var(--space-2);
    width: 100%;

    @media (min-width: 600px){
      gap: var(--space-3, 24px);
      width: max-content;
    }
  }
}
