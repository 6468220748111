.block-to-text-button {
  align-items: center;
  border: 2px solid transparent;
  border-block-end: 4px solid transparent;
  border-radius: var(--space-1);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-1-5);
  font-weight: var(--font-weight-bold);
  gap: var(--space-1);
  inline-size: fit-content;
  justify-content: center;
  padding: var(--space-1) var(--space-2);
  position: relative;
  text-align: center;
  text-decoration: none;
  /* Fixes a rendering error that sometimes occurs due to negative z-index */
  transform: scale(1);
}

.block-to-text-button:active {
  border-block-end: 2px solid transparent;
  margin-block-start: 2px;
}

.block-to-text-button:disabled {
  border-block-end: 4px solid transparent;
  margin-block-start: 0;
}

.block-to-text-button:focus-visible {
    outline: 2px solid var(--rpf-brand-raspberry);
    outline-offset: 2px;
  }

.block-to-text-button--primary {
  background-color: var(--rpf-grey-600);
  border-color: var(--rpf-grey-900);
  color: var(--rpf-white);
}

.block-to-text-button--primary svg {
  fill: var(--rpf-white);
}

.block-to-text-button--primary:hover {
  background-color: color-mix(in srgb, var(--rpf-grey-600), var(--rpf-white) 20%);
}

.block-to-text-button--primary:active {
  background-color: var(--rpf-grey-600);
  border-color: var(--rpf-grey-900);
}

.block-to-text-button--primary:disabled {
  background-color: var(--rpf-grey-100);
  border-color: var(--rpf-grey-400);
  color: var(--rpf-grey-400);
}

.block-to-text-button--primary:disabled svg {
  fill: var(--rpf-grey-400);
}


.block-to-text-button--secondary {
  background-color: var(--rpf-white);
  border-color: var(--rpf-grey-900);
  color: var(--rpf-grey-900);
}

.block-to-text-button--secondary svg {
  fill: var(--rpf-grey-900);
}

.block-to-text-button--secondary:hover {
  background-color: var(--rpf-off-white);
}

.block-to-text-button--secondary:active {
  background-color: var(--rpf-white);
  border-color: var(--rpf-grey-900);
}

.block-to-text-button--secondary:disabled {
  background-color: var(--rpf-white);
  border-color: var(--rpf-grey-400);
  color: var(--rpf-grey-400);
}

.block-to-text-button--secondary:disabled svg {
  fill: var(--rpf-grey-400);
}


.block-to-text-button--success {
  background-color: var(--rpf-green-900);
  border-color: color-mix(in srgb, var(--rpf-green-900), var(--rpf-black) 30%);
  color: var(--rpf-white);
}

.block-to-text-button--success svg {
  fill: var(--rpf-white);
}

.block-to-text-button--success:hover {
  background-color: color-mix(in srgb, var(--rpf-green-900), var(--rpf-white) 20%);
}

.block-to-text-button--success:active {
  background-color: var(--rpf-green-900);
  border-color: color-mix(in srgb, var(--rpf-green-900), var(--rpf-black) 30%);
}

.block-to-text-button--success:disabled {
  background-color: var(--rpf-grey-100);
  border-color: var(--rpf-grey-400);
  color: var(--rpf-grey-400);
}

.block-to-text-button--success:disabled svg {
  fill: var(--rpf-grey-400);
}


.block-to-text-button--success-secondary {
  background-color: var(--rpf-white);
  border-color: color-mix(in srgb, var(--rpf-green-900), var(--rpf-black) 30%);
  color: var(--rpf-green-900);
}

.block-to-text-button--success-secondary svg {
  fill: var(--rpf-green-900);
}

.block-to-text-button--success-secondary:hover {
  background-color: var(--rpf-off-white);
}

.block-to-text-button--success-secondary:active {
  background-color: var(--rpf-white);
  border-color: color-mix(in srgb, var(--rpf-green-900), var(--rpf-black) 30%);
}

.block-to-text-button--success-secondary:disabled {
  background-color: var(--rpf-white);
  border-color: var(--rpf-grey-400);
  color: var(--rpf-grey-400);
}

.block-to-text-button--success-secondary:disabled svg {
  fill: var(--rpf-grey-400);
}

.block-to-text-button--option {
  font-family: 'RobotoMono';
  background-color: var(--rpf-white);
  border-color: var(--rpf-grey-150);
  color: var(--rpf-text-primary);
}

.block-to-text-button--option:hover {
  background-color: var(--rpf-grey-50);
}

.block-to-text-button--option:active {
  border-color: var(--rpf-grey-150);
}

.block-to-text-button--option-selected {
  background-color: var(--rpf-grey-150);
  border-color: var(--rpf-grey-150);
  cursor: default;

  .text {
    visibility: hidden;
  }
}

.block-to-text-button--option-selected:hover {
  background-color: var(--rpf-grey-150);
}

.block-to-text-button--option-selected:active {
  margin-block-start: 0;
}

.block-to-text-button__outer {
  display: flex;
  width: fit-content;
  min-block-size: var(--rpf-button-lg-height);

  .block-to-text-button {
    flex: 1;
  }
}

.block-to-text-button--icon-only {
  line-height: var(--rpf-button-lg-height);
  padding: 0;
  text-align: center;
  inline-size: var(--rpf-button-lg-height);
}

.block-to-text-button__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
