@use "@raspberrypifoundation/design-system-core/scss/mixins/_typography.scss";
@use '@raspberrypifoundation/design-system-core/scss/components/squiggle.scss';
@import "@raspberrypifoundation/design-system-core/scss/properties/colors/_greys.scss";
@import "@raspberrypifoundation/design-system-core/scss/properties/_spacing.scss";

.c-step {
  align-items: flex-start;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--space-2);
  align-items: center;
  width: 100%;

  @media (min-width: 800px) {
    align-items: flex-start;
    flex-direction: row;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
  }

  &__inputs {
    background: var(--rpf-white);
    border-radius: var(--space-1);
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--space-2);
    max-width: 100%;
    padding-block: var(--space-3);
    overflow: visible;
    box-sizing: border-box;
    width: 100%;

    @media (min-width: 800px) {
      overflow: auto;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    padding-inline: var(--space-3);

    h2 {
      margin-bottom: var(--space-2);
    }

    h3 {
      margin: 0;
      padding: 0;
    }
  }

  &__instructions {
    display: flex;
    flex-direction: column;
    gap: var(--space-2);
    padding-inline: var(--space-3);

    &-content {
      display: flex;
      flex-direction: column;
      gap: var(--space-1-5);
    }

    blockquote {
      background: var(--theme-100);
      border-left: 4px solid var(--theme-300);
      margin-inline: 0;
      padding: var(--space-2);
    }

    ul {
      list-style-position: outside;
      list-style-type: disc;
      text-align: left;
      padding-inline-start: var(--space-3);
    }

    li {
      margin-block: var(--space-1);
      display: list-item;
    }

    code {
      background-color: var(--rpf-grey-700);
      border-radius: var(--space-1);
      color: var(--rpf-white);
      padding: calc(0.75 * var(--space-0-125)) var(--space-0-5);
    }
  }

  &__output {
    background-color: var(--rpf-white);
    border-radius: var(--space-1);
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--space-2);
    height: fit-content;
    width: 100%;
    padding: var(--space-3);

    @media (min-width: 800px) {
      max-width: 500px;
      position: sticky;
      top: var(--space-3);
    }
  }

  .rpf-squiggle-heading {
    width: fit-content;
  }

  hr {
    background-color: var(--rpf-grey-50);
    border: none;
    height: 3px;
    width: 100%;
  }
}
