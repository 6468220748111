// Landing page media queries: default, 400, 1200

.home {
  align-items: center;
  background: var(--theme-100);
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--space-4);
  justify-content: center;
  padding-block: var(--space-4);
  padding-inline: var(--space-3);
  text-align: center;
  width: 100%;

  h1 {
    font-size: var(--font-size-3);
    font-weight: var(--font-weight-bold);
  }

  @media (min-width: 400px) {
    margin: 0 auto;
    padding-inline: var(--space-4);
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    gap: var(--space-10);
    padding-inline: var(--space-10);

    h1 {
      font-size: var(--font-size-5); 
    }
  }
}

.home__image {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 360px;

  @media (min-width: 1200px) {
    max-width: 600px;
  }
}

.home__header {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  max-width: 540px;
  text-align: center;

  @media (min-width: 1200px) {
    align-items: flex-start;
    max-width: none;
    text-align: start;
  }
}

.home__alpha {
  align-items: flex-start;
  background: var(--theme-200);
  border-radius: var(--space-2);
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  padding: var(--space-2);
  text-align: start;
  margin-block-start: var(--space-1);

  @media (min-width: 600px) {
    align-items: center;
    gap: var(--space-2);
    flex-direction: row;
  }
}
.home__alpha--tag {
  align-self: flex-start;
  background-color: var(--theme-400);
  border-radius: var(--space-2);
  color: var(--rpf-white);
  font-weight: var(--font-weight-bold);
  padding: var(--space-0-5) var(--space-1-5);
}
