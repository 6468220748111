.card {
  background-color: var(--rpf-white);
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  border-radius: var(--space-2);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
}

.card__image {
  border-start-start-radius: var(--space-2);
  border-start-end-radius: var(--space-2);
  height: auto;
  width: 100%;
}

.card__content {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  padding: var(--space-3);
}
