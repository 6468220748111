@use "@raspberrypifoundation/design-system-core/scss/mixins/_typography.scss";
@import "@raspberrypifoundation/design-system-core/scss/properties/colors/_alert.scss";
@import "@raspberrypifoundation/design-system-core/scss/components/alert.scss";

.c-runbar {
  background-color: var(--rpf-white);
  border-radius: var(--space-2);
  display: flex;
  flex-flow: wrap;
  gap: var(--space-2);
  padding: var(--space-2);


  &--success {
    background-color: var(--rpf-alert-success-highlight);
    color: var(--rpf-alert-success);
  }

  &__success-message {
    @include typography.regular-1-5;

    align-items: center;
    display: flex;
    flex: 1 0 auto;
    font-weight: var(--font-weight-bold);
    gap: var(--space-1);

    svg {
      height: var(--space-4);
    }
  }

  &__controls {
    display: flex;
    flex-direction: column;
    gap: var(--space-2);
    width: 100%;

    @media (min-width: 800px) {
      flex-direction: row;
      margin-left: auto;
      width: fit-content;
    }
  }
}

.c-runbar__button {
  width: 100%;
}

.block-to-text-button--run {
  white-space: nowrap;
}
