ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: var(--rpf-white);
  align-items: center;
  text-align: center;
  height: 100%;
}

li {
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

li a {
  display: block;
  color: var(--rpf-text, #212121);
  padding: var(--space-1-5) var(--space-2);
  text-decoration: none;
}
