@use '@raspberrypifoundation/design-system-core/scss/mixins/_typography.scss';
@import '@raspberrypifoundation/design-system-core/scss/properties/colors/_text.scss';
@import '@raspberrypifoundation/design-system-core/scss/properties/colors/_decorative.scss';
@import '@raspberrypifoundation/design-system-core/scss/properties/colors/_greys.scss';
@import '@raspberrypifoundation/design-system-core/scss/properties/_spacing.scss';

@font-face {
  font-family: 'RobotoMono';
  src: local('RobotoMono'), url(../assets/fonts/Roboto_Mono/RobotoMono.ttf) format('truetype');
}

.c-editor-inputs {
  @include typography.regular-1;
  margin-bottom: var(--space-3);

  &__options, &__text {
    font-family: 'RobotoMono';
  }

  &__options {
    display: flex;
    flex-direction: column;
    margin-top: var(--space-3);

    @media (min-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__option {
    background-color: var(--rpf-white);
    border-radius: var(--space-2);
    border: 2px solid var(--rpf-grey-150);
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-2);
    padding: var(--space-2);

    div {
      @include typography.regular-1;
    }

    select{
      @include typography.regular-1;
      font-family: 'RobotoMono';

      padding: var(--space-0-5);
      border-radius: var(--space-0-5);
    }
  }

  .cm-editor {
    width: 100%;
    background: var(--rpf-grey-700);
    border-radius: var(--space-2);
    color: var(--rpf-text-primary-darkmode);
    overflow: auto;
  }

  .cm-focused {
    outline: none;
  }

  .cm-content {
    padding: 0;
  }

  .cm-scroller {
    border-radius: var(--space-2);
    height: unset;
  }

  .cm-gutters, .cm-content{
    margin-block: var(--space-2);
  }

  .cm-lineNumbers {
    background: var(--rpf-grey-700);
    color: var(--rpf-text-secondary-darkmode);
    padding-inline-end: var(--space-0-5);
    min-width: var(--space-5);
  }

  .cm-gutterElement, .cm-line{
    height: var(--space-4);
    line-height: var(--space-4);
  }

  .cm-line {
    padding-inline-start: var(--space-2);
    overflow: hidden;
    height: 100%;

    * {
      vertical-align: middle;
    }
  }


  input.gap-style{
    border: none;
    font-size: 16px;
  }

  .gap-style {
    $activeColour: var(--rpf-blue-600);

    align-items: center;
    background: var(--rpf-grey-900);
    border-radius: 6px;
    color: var(--rpf-black);
    display: inline-flex;
    font-family: 'RobotoMono';
    height: var(--space-3);
    padding: var(--space-0-5);

    &__slot {
      min-width: var(--space-10);
      outline-offset: -2px;
      outline: 2px dashed var(--rpf-grey-100);

      &--filled {
        background: var(--rpf-white);
        cursor: pointer;
        min-width: min-content;
        outline-offset: -1px;
        outline: 1px solid var(--rpf-grey-150);

        &:hover {
          background: var(--rpf-grey-100);
          outline-offset: -1px;
          outline: 1px solid var(--rpf-grey-150);
        }

        &:active, &:focus{
          outline-offset: -2px;
          outline: 2px solid $activeColour;
        }
      }
    }

    &__text {
      caret-color: var(--rpf-text-primary-darkmode);
      color: var(--rpf-text-primary-darkmode);
      line-height: var(--space-4);
      outline-offset: -2px;
      outline: 2px solid var(--rpf-grey-150);

      &:active, &:focus{
        outline-offset: -2px;
        outline: 2px solid $activeColour;
      }
    }
  }
}
